import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import { Modal } from 'antd'

import MedicinesTable from './MedicinesTable'
import NewMedicineModal from './NewMedicineModal'

import { getMedicines } from '../services'
import { handleError } from '../../../helpers'

export default function AuthorizationMedicinesListModal({ 
    visible, onClose, authorization 
}) {
    const [medicines, setMedicines] = useState(null)
    const [showNewModal, setShowNewModal] = useState(false)

    useEffect(() => {
        !medicines && visible && getMedicines({ 
            'filter[authorization_id]': authorization.id 
        })
            .then(response => setMedicines(response.data.data))
            .catch(error => handleError(error))
    }, [ visible, authorization.id, medicines ])

    return (
        <React.Fragment>
            <Modal
                open={visible}
                onCancel={onClose}
                title={`Medicamentos Autorización ${authorization.authorization_key}`}
                loading={!medicines}
                footer={null}
                width={900}
            >
                <MedicinesTable 
                    medicines={medicines}
                    reload={() => setMedicines(null)}
                />
                <Button color='primary' onClick={() => setShowNewModal(true)}>
                    Agregar Medicamento
                </Button>
            </Modal>           
            { showNewModal && (
                <NewMedicineModal
                    visible={showNewModal}
                    onClose={() => setShowNewModal(false)}
                    authorization={authorization}
                    reload={() => setMedicines(null)}
                />
            )}
        </React.Fragment>
    )
}
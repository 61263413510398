import React, { useState } from 'react'
import { Button, Badge } from 'react-bootstrap'
import { Table, Divider, Tooltip, Popconfirm, message } from 'antd'
import { Link } from 'react-router-dom'
import { faCalendarAlt, faCheckDouble, faEdit, faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'

import EditDeliveryModal from './EditDeliveryModal'
import VerifyDeliveryModal from './VerifyDeliveryModal'
import Pagination from '../../../components/Pagination'

import { updateDelivery } from '../services'
import { handleError } from '../../../helpers'

export default function DeliveryTable({ 
   setDrawerId, 
   deliveries, 
   pagination, 
   setPagination,
   reload,
   sort,
   setSort,
}) { 
   const [editDateModal, setEditDateModal] = useState(null)
   const [showVerifyModal, setShowVerifyModal] = useState(null)

   const columns = [
      { 
         title: '#',
         dataIndex: 'id',
         render: text => `#${text}`
      },
      { 
         title: 'Fecha Entrega',
         dataIndex: 'schedule_date',
         render: text => moment(text).format('YYYY-MM-D'),
         sorter: true,
         defaultSortOrder: sort === 'schedule_date' ? 'ascend' : sort === '-schedule_date' ? 'descend' : '',
      },
      {
         title: 'Autorizaciones',
         dataIndex: 'authorizations_keys',
         render: t => t ? t : <em>Sin ingresar</em>
      },
      {
         title: 'Verificada',
         dataIndex: 'verified_at',
         render: (t, r) => !t ? (r.status === 'pendding' ? <Button variant="outline-primary"
         size="sm" onClick={() => setShowVerifyModal(r.id)}>Verificar</Button> : <em>Sin verificar</em>) : t,
         sorter: true,
         defaultSortOrder: sort === 'verified_at' ? 'ascend' : sort === '-verified_at' ? 'descend' : '',
      },
      {
         title: 'Estado',
         dataIndex: 'status_badge',
         render: t => <Badge bg={t.variant}>{t.label}</Badge>,
      },
      {
         title: 'Estado de Entrega',
         dataIndex: 'status',
         render: (text, record) => {
            switch (text) {
               case 'voided': return 'Anulada'
               case 'delivered': return 'Entregada'
               case 'verified': return 'Verificada'
               case 'pendding': return 'Pendiente'
               default: return 'Sin Estado'
            }
         }
      },
      { 
         title: 'Acciones',
         dataIndex: 'actions',
         width: '150px',
         render: (text, record) => (
            <React.Fragment>
               <Tooltip title="Ver Detalles">
                  <Button 
                     onClick={()=>setDrawerId(record.id)}
                     variant="outline-primary"
                     size="sm"
                  >                  
                        <FontAwesomeIcon icon={faEdit} />
                  </Button>
               </Tooltip>
               <Divider type="vertical" />
               <Link to={`/deliveries/print/${record.id}`} target="_blank">
                  <Tooltip title="Imprimir">
                     <Button variant="outline-primary" size="sm">
                        <FontAwesomeIcon icon={faPrint} />
                     </Button>
                  </Tooltip>
               </Link>
               { record.status === 'pendding' && (
                  <React.Fragment>
                     <Divider type="vertical" />
                     <Tooltip title="Reprogramar Fecha">
                        <Button variant="outline-primary" size="sm" onClick={()=>setEditDateModal(record)}>
                           <FontAwesomeIcon icon={faCalendarAlt} />
                        </Button>
                     </Tooltip>
                  </React.Fragment>
               )}
               { record.status === 'verified' && (
                  <React.Fragment>
                     <Divider type="vertical" />
                     <Tooltip title="Marcar como entregada">
                        <Popconfirm
                           title="¿Desea marcar la entrega como entregada y finalizada?"
                           okText="Finalizar Entrega"
                           cancelText="Cancelar"
                           onConfirm={() => handleMarkAsDelivered(record.id)}
                        >
                           <Button variant="outline-primary" size="sm">
                              <FontAwesomeIcon icon={faCheckDouble} />
                           </Button>
                        </Popconfirm>
                     </Tooltip>
                  </React.Fragment>
               )}
               {/* <Divider type="vertical" />
               <Tooltip title="Próxima Fecha de Entrega">
                  <Button variant="outline-primary" size="sm" onClick={() => setShowNextDeliveryAtModal(record.user_id)}>
                     <FontAwesomeIcon icon={faCalendarPlus} />
                  </Button>
               </Tooltip> */}
            </React.Fragment>
         )
      }
   ]

   const handleTableChange = (pagination, filters, sorter) => {
      if(sorter.column) setSort(`${sorter.order === 'ascend' ? '' : '-'}${sorter.column.dataIndex}`)
      else setSort('')
      reload()
   }

   const handleMarkAsDelivered = id => {
      updateDelivery(id, { delivered_at: moment().format('YYYY-MM-DD HH:mm:ss') })
         .then(res => {
            message.success(res.data.message)
            reload()
         })
         .catch(handleError)
   }
   
   return (
      <React.Fragment>
         <Table
            dataSource={deliveries}
            columns={columns}
            rowKey={r => r.id}
            onChange={handleTableChange}
            pagination={false}
            size="small"
            expandable={{ 
               expandedRowRender: record => (
                  <React.Fragment>
                     <p><strong>Paciente: </strong> { record.user.name.includes('#') ? <em>Sin nombre</em> : record.user.name } (CC {record.user.document})</p>
                  </React.Fragment>
               )
            }}
         />
         <Pagination
            reload={reload}
            pagination={pagination}
            setPagination={setPagination}
         />
         { editDateModal && (
            <EditDeliveryModal 
               visible
               onClose={() => setEditDateModal(null)}
               delivery={editDateModal}
               reload={reload}
            />
         )}
         { showVerifyModal && (
            <VerifyDeliveryModal 
               visible
               deliveryId={showVerifyModal}
               onClose={() => setShowVerifyModal(null)}
               reload={reload}
            />
         )}
      </React.Fragment>
   )
}
import asisfarmaApi from '../../services/asisfarmaApi'

export const getMedicines = params => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get('/medicines',{params}).then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const getMedicine = (id,params) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .get(`/medicines/${id}`, {params})
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const createMedicine = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/medicines', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const updateMedicine = (id,data) => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .put(`/medicines/${id}`, data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteMedicine = id => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .delete(`/medicines/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)
import React, {useState} from 'react'
import { Layout, Breadcrumb } from 'antd'
import styled from 'styled-components'
import SideMenu from './partials/SideMenu'
import UserMenu from './partials/UserMenu'
import moment from 'moment'

const { Header, Content, Footer, Sider } = Layout

const LogoContainer = styled.div`
   text-align: center;
   background-color: #fff;
   padding: 13px 10px;
   margin-bottom: 20px;
`;

export default function AppLayout({ title, children }) {
   const [collapsed, setCollapsed] = useState(true)

   return (
      <Layout style={{ minHeight: '100vh' }}>
         <Sider collapsible collapsed={collapsed} onCollapse={()=>setCollapsed(!collapsed)}>
            <LogoContainer>
               <img src="/images/logo-horizontal.png" width="170px" alt="logo instafarma" />
            </LogoContainer>
            <SideMenu />
         </Sider>
         <Layout className="site-layout">
            <Header className="site-layout-background">
               <div className="float-right">
                  <UserMenu />
               </div>
            </Header>
            <Content style={{ margin: '0 16px' }}>
               <Breadcrumb 
                  style={{ margin: '16px 0' }} 
                  items={[
                     { title: 'Aplicativo IPS' },
                     { title: title },
                  ]}
               />
               <div className="site-layout-background" style={{ padding: 15 }}>
                  {children}
               </div>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
               Instafarma ©{moment().format('YYYY')} - llevando salud a casa
               {" "} - Versión {process.env.REACT_APP_VERSION}
            </Footer>
         </Layout>
      </Layout>
   )
}
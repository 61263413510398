import React from 'react'
import { Empty, Popconfirm, Table, Tooltip } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { deleteMedicine } from '../services'
import { handleError, handleSuccess } from '../../../helpers'

export default function MedicinesTable({ medicines, reload }) {

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
        },
        {
            title: 'Cantidad Solicitada',
            dataIndex: 'quantity_requested',
        },
        {
            title: 'Cantidad Entregada',
            dataIndex: 'quantity_delivered',
        },
        {
            title: 'PLU',
            dataIndex: 'plu',
        },
        {
            title: 'Acciones',
            render: (t, r) => (
                <React.Fragment>
                    <Tooltip title='Eliminar'>
                        <Popconfirm
                            title="Esta acción no se puede revertir"
                            okText="Eliminar"
                            okButtonProps={{ danger:true }}
                            cancelText="Cancelar"
                            onConfirm={() => handleDelete(r.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} className='text-link' />
                        </Popconfirm>
                    </Tooltip>
                </React.Fragment>
            )
        }
    ]

    const handleDelete = id => {
        deleteMedicine(id)
            .then(res => handleSuccess(res.data.message, reload))
            .catch(error => handleError(error))
    }

    return (
        <React.Fragment>
            <Table 
                dataSource={medicines}
                columns={columns}
                rowKey={r => r.id}
                loading={!medicines}
                size="small"
                locale={{ emptyText: <Empty description="No hay medicamentos registrados." /> }}
                className='mb-10'
            />
        </React.Fragment>
    )
}
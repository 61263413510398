import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Card, Empty, message } from 'antd'
import { Button, Form, Spinner } from 'react-bootstrap'

import { getSettings, updateSetting } from '../services'
import { handleError } from '../../../helpers'

export default function DeliveryLimitTab() {
	const { handleSubmit, register, formState: { errors } } = useForm()
	const [currentSettings, setCurrentSettings] = useState(null)

	useEffect(() => {
		!currentSettings && getSettings({
			'filter[key]': 'delivery_limit'
		})
			.then(res => setCurrentSettings(res.data.data))
			.catch(handleError)
	}, [ currentSettings ])

	const onSubmit = values => {
		updateSetting(currentSettings[0].id, { value: parseInt(values.value) })
			.then(res => {
				message.success(res.data.message)
				setCurrentSettings(null)
			})
			.catch(handleError)
	}

	return (
		<Card>
			{ currentSettings ? (
				<React.Fragment>
					{ currentSettings.length > 0 ? (
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Form.Group className="form-group">
								<Form.Label>Límite de Entregas por Día <span className='text-danger'>*</span></Form.Label>
								<Form.Control 
									type="number"
									{...register("value", { required:true })}
									defaultValue={currentSettings[0].value}
									style={{ maxWidth: '250px' }}
								/>
								{ errors.value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
							</Form.Group>
							<Button color="primary" type="submit">Actualizar Límite</Button>
						</Form>
					) : (
						<Empty description="No existe limite definido. Favor contactar a soporte." />
					)}
				</React.Fragment>
			) : <Spinner animation="border" />}
		</Card>
	)
}
import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { Modal, DatePicker, message } from 'antd'
import { Form, Button } from 'react-bootstrap'
import moment from 'moment'

import { getUri } from '../../../services/asisfarmaApi'
import { storeExportToken } from '../../ExportTokens/services'
import { handleError } from '../../../helpers'

const { RangePicker } = DatePicker

export default function DeliveriesExport({ scope }) {
	const { user } = useSelector(state => state.auth)
	const [open, setOpen] = useState(false)
	const [from, setFrom] = useState(moment().subtract(30,'days').format('YYYY-MM-DD'))
	const [to, setTo] = useState(moment().format('YYYY-MM-DD'))
	const [confirmed, setConfirmed] = useState(0)

	const handleExport = () => {
		const token = Date.now() + Math.floor(1000 + Math.random() * 9000);
		storeExportToken({
			user_id: user.id,
			type: `Exportar ${ scope === 'deliveries' ? 'Domicilios' : 'Entregas en Farmacia' }`,
			token,
		})
			.then(() => {
				const url = getUri()
				window.open(`${url}/exports/deliveries/${scope}/${from}/${to}/${confirmed}/${token}`)
				message.success('Entregas exportadas', 10)
				setOpen(false)
			})
			.catch(handleError)
	}

	const handleDatesChange = dates => {
		setFrom(dates[0].format('YYYY-MM-DD'))
		setTo(dates[1].format('YYYY-MM-DD'))
	}

	return (
		<React.Fragment>
			<Button variant="outline-primary" onClick={() => setOpen(true)}>
				Exportar { scope === 'deliveries' ? 'Domicilios' : 'Entregas en Farmacia' }
			</Button>
			<Modal
				open={open}
				onOk={handleExport}
				okText="Exportar a Excel"
				onCancel={() => setOpen(false)}
				cancelText="Cancelar"
				title="Exporta Entregas"
			>
				<Form.Group className="form-group mt-10">
					<Form.Label className="block">Rango de Fechas</Form.Label>
					<RangePicker
						defaultValue={[moment(from, 'YYYY-MM-DD'), moment(to, 'YYYY-MM-DD')]}
						onChange={dates => handleDatesChange(dates)}
					/>
				</Form.Group>
				<Form.Group className="form-group">
					<Form.Label>Estado de Entrega</Form.Label>
					<Form.Control
						as="select"
						value={confirmed}
						onChange={e=>setConfirmed(e.target.value)}
					>
						<option value={0}>Pendientes de Entregar</option>
						<option value={1}>Entregas Confirmadas</option>
						<option value={2}>Entregas Canceladas</option>
					</Form.Control>
				</Form.Group>
			</Modal>
		</React.Fragment>
	)
}
import React from 'react'
import { createRoot } from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import BugsnagPerformance from '@bugsnag/browser-performance'

import { store, persistor } from './store'

import 'bootstrap/dist/css/bootstrap.min.css'
import './theme.css'
import './helpers.css'

import App from './App'

Bugsnag.start({
   apiKey: 'd610d7cc137aba854e3caf69b8f04018',
   plugins: [new BugsnagPluginReact()]
})
BugsnagPerformance.start({ apiKey: 'd610d7cc137aba854e3caf69b8f04018' })

const root = createRoot(document.getElementById('root'))

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

root.render(
   <React.StrictMode>
      <ErrorBoundary>
         <HashRouter>
            <Provider store={store}>     
               <PersistGate loading="Cargando..." persistor={persistor}>
                  <App />
               </PersistGate>
            </Provider>
         </HashRouter>
      </ErrorBoundary>
   </React.StrictMode>
)
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form } from 'react-bootstrap'
import { Modal } from 'antd'

import MedicineForm from './MedicineForm'

import { createMedicine } from '../services'
import { handleError, handleSuccess } from '../../../helpers'

export default function NewMedicineModal({ visible, onClose, authorization, reload }) {
    const { handleSubmit, register, formState: { errors } } = useForm()
    const [loading, setLoading] = useState(false)

    const onSubmit = values => {
        setLoading(false)
        values.authorization_id = authorization.id
        createMedicine(values)
            .then(res => handleSuccess(res.data.message, reload, onClose))
            .catch(error => handleError(error, setLoading))
    }

    return (
        <Modal
            open={visible}
            onCancel={onClose}
            title={`Nuevo Medicamento Autorización ${authorization.authorization_key}`}
            loading={loading}
            footer={null}
        >
            <Form onSubmit={handleSubmit(onSubmit)}>
                <MedicineForm 
                    register={register}
                    errors={errors}
                />
                <Button color='primary' type="submit">
                    Agregar Medicamento
                </Button>
            </Form>
        </Modal>
    )
}
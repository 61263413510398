import React from 'react'
import { Button, Badge } from 'react-bootstrap'
import { Table } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'

export default function TicketTable({ 
   tickets, 
   setDrawerId, 
   pagination,
   setPagination, 
   reload,
   sort,
   setSort,
}) {   

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         render: (t, r) => <span className='text-link' onClick={()=>setDrawerId(r.id)}>{t}</span>
      },
      { 
         title: 'Fecha de Creación',
         dataIndex: 'created_at',
         render: text => moment(text).format('D MMM YYYY h:mma'),
         sorter: true,
         defaultSortOrder: sort === 'created_at' ? 'ascend' : sort === '-created_at' ? 'descend' : '',
      },
      {
         title: 'Tipo',
         dataIndex: 'ticket_type',
         render: type => type.name
      },
      {
         title: 'Paciente',
         dataIndex: 'user',
         render: user => user.name
      },
      { 
         title: 'Estado',
         dataIndex: 'status_badge',
         width: '120px',
         render: t => (
            <Badge bg={t.variant}>{t.label}</Badge>
         ),
      },
      { 
         title: 'Acciones',
         dataIndex: 'actions',
         render: (text, record) => (
            <React.Fragment>
               <Button 
                  onClick={()=>setDrawerId(record.id)}
                  variant="outline-primary"
                  size="sm"
               >
                  Ver Detalles
               </Button>         
            </React.Fragment>
         )
      }
   ]

   const handleTableChange = (pagination, filters, sorter) => {
      if(sorter.column) setSort(`${sorter.order === 'ascend' ? '' : '-'}${sorter.column.dataIndex}`)
      else setSort('')
      reload()
   } 
   
   return (
      <React.Fragment>
         <Table
            dataSource={tickets}
            columns={columns}
            rowKey={r => r.id}
            onChange={handleTableChange}
            pagination={false}
            size="small"
         />
         <Pagination
            reload={reload}
            pagination={pagination}
            setPagination={setPagination}
         />
      </React.Fragment>
   )
}
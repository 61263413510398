import React, {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useForm } from "react-hook-form"
import { Form, Button } from 'react-bootstrap'
import Bugsnag from '@bugsnag/js'

import AuthLayout from '../../layouts/AuthLayout'

import {login} from './services'
import {userLogin} from './actions'
import { handleError } from '../../helpers'

export default function Login() {
   const dispatch = useDispatch()
   const { handleSubmit, register, formState: { errors } } = useForm()
   const [ loading, setLoading ] = useState(false)
   const authToken = useSelector(state => state.auth.token)

   if(authToken) return <Redirect to="/" />

   const onSubmit = values => {
      setLoading(true)      
      values.isAdmin = 1
      !authToken && login(values)
         .then(response => {
            const user = response.data.user
            Bugsnag.setUser(user.id, user.email, user.name)
            dispatch(userLogin(response.data))
         })
         .catch(error => {
            setLoading(false)
            handleError(error)
         })
   };

   return (
      <AuthLayout>
         <h2 className="mb-0">Inicio de Sesión</h2>
         <p className="mb-20">Ingrese sus credenciales</p>
         <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="form-group">
               <Form.Label>Documento</Form.Label>
               <Form.Control
                  type="number"
                  {...register("document", { required: true })}
               />
               { errors.email && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Form.Group className="form-group">
               <Form.Label>Contraseña</Form.Label>
               <Form.Control
                  type="password"
                  {...register("password", { required: true })}
               />
               { errors.password && <Form.Text className="text-warning">Este campo es requerido.</Form.Text> }
            </Form.Group>
            <div className="float-right mt-5">
               <Button type="submit" disabled={loading}>
                  { !loading ? 'Iniciar Sesión' : 'Cargando...' }
               </Button>
            </div>
            <a href="https://app.asisfarma.com.co/auth/forgot" target="_blank" rel="noopener noreferrer">
               ¿Olvidó su contraseña?
            </a>
         </Form>
      </AuthLayout>
   )
}
import React, {useState,useEffect} from 'react'
import { Link } from 'react-router-dom'
import { Drawer } from 'antd'
import { Spinner, Button } from 'react-bootstrap'

import AuthorizationDetails from './AuthorizationDetails'
import SendSMS from '../../Tickets/partials/SendSMS'
import VoidActions from '../../../components/VoidActions'
import UpdateUserModal from '../../Users/partials/UpdateUserModal'
import VerifyDeliveryModal from './VerifyDeliveryModal'

import {getDelivery} from '../services'
import { handleError } from '../../../helpers'

export default function DeliveryDrawer({ drawerId, closeDrawer, reload }) {
   const [delivery, setDelivery] = useState(null)
   const [updateUserModal, setUpdateUserModal] = useState(null)
   const [showVerifyModal, setShowVerifyModal] = useState(null)

   useEffect(()=>{
      !delivery && getDelivery(drawerId, {
         include: 'address,user,authorizations.medicines,attachments'
      })
         .then(response => setDelivery(response.data.data))
         .catch(error => handleError(error))
   }, [delivery, drawerId])

   return (
      <Drawer
         title="Detalles de Entrega"
         placement="right"
         onClose={() => {
            setDelivery(null)
            reload()
            closeDrawer()
         }}
         open={drawerId!==null}
         width={450}
      >
         {delivery ? (
            <React.Fragment>
               <AuthorizationDetails delivery={delivery} />
               { !delivery.verified_at && !delivery.voided_at  && (
                  <div className="mb-10">
                     <Button 
                        variant="outline-primary" 
                        onClick={() => setShowVerifyModal(true)}
                     >
                        Verificar Entrega
                     </Button>
                  </div>
               )}
               <Link to={`/deliveries/print/${drawerId}`} target="_blank">
                  <Button variant="outline-primary" className="mr-10">Imprimir</Button>
               </Link>
               
               <SendSMS user={delivery.user} reload={()=>{}} />
               
               <VoidActions 
                  delivery={delivery} 
                  reload={() => {
                     setDelivery(null)
                     reload()
                  }} 
               />

               <div className="mt-10">
                  <Button 
                     variant="outline-primary" 
                     onClick={() => setUpdateUserModal(delivery.user)}
                  >
                     Actualizar Usuario
                  </Button>
               </div>
               { updateUserModal && (
                  <UpdateUserModal 
                     user={updateUserModal}
                     onClose={() => setUpdateUserModal(null)}
                     reload={() => setDelivery(null)}
                  />
               )}
               { showVerifyModal && (
                  <VerifyDeliveryModal 
                     visible
                     deliveryId={delivery.id}
                     onClose={() => setShowVerifyModal(false)}
                     reload={() => setDelivery(null)}
                  />
               )}
            </React.Fragment>
         ) : (
            <Spinner animation="grow" />
         )}         
      </Drawer>
   )
}
import React, {useState, useEffect} from 'react'
import { Spinner } from 'react-bootstrap'
import { Card } from 'antd'

import PickupDrawer from './partials/PickupDrawer'
import PickupTable from './partials/PickupsTable'
import PickupsListTopBar from './partials/PickupsListTopBar'

import { getStores } from './services'
import { getDeliveries } from '../Deliveries/services'
import { handleError, parsePagination } from '../../helpers'

export default function PickupsList() {
   const [drawerId, setDrawerId] = useState(null)
   const [pagination, setPagination] = useState({page: 1, per_page: 10, total: 0})
   const [sort, setSort] = useState("-schedule_date")
   const [pickups, setPickups] = useState(null)
   const [filterType, setFilterType] = useState('authorizations.authorization_key')
   const [filterValue, setFilterValue] = useState('')
   const [stores, setStores] = useState(null)
   const [selectedStores, setSelectedStores] = useState([])

   useEffect(()=>{
      !stores && getStores()
         .then(res => {
            setSelectedStores(res.data.data.map(store => store.id))
            setStores(res.data.data)
         })
         .catch(error => handleError(error))
   }, [stores])

   useEffect(()=>{
      !pickups && stores && getDeliveries({
         ...pagination,
         [`filter[${filterType}]`]: filterValue,
         'filter[store_id]': selectedStores.join(','),
         [`filter[pickups]`]: 1,
         sort,
         include: 'user,store,authorizations'
      })
         .then(res => {
            setPickups(res.data.data)
            setPagination(parsePagination(res))
         })
         .catch(error => handleError(error))
   }, [pickups, pagination, filterType, filterValue, sort, stores, selectedStores])

   return (
      <React.Fragment>
         <PickupsListTopBar 
            filterType={filterType}
            setFilterType={setFilterType}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            setPickups={setPickups}
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
            stores={stores}
         />
         <Card title="Listado de Entregas en Farmacia">
            { pickups ? (
               <PickupTable 
                  setDrawerId={setDrawerId}
                  pickups={pickups}
                  pagination={pagination}
                  setPagination={setPagination}
                  reload={()=>setPickups(null)}
                  sort={sort}
                  setSort={setSort}
               />
            ) : (
               <Spinner animation="grow" />
            )}
         </Card>
         { drawerId && <PickupDrawer 
            drawerId={drawerId} 
            closeDrawer={()=>setDrawerId(null)}
            reload={()=>setPickups(null)}
         /> }
      </React.Fragment>
   )
}
import React, {useState, useEffect} from 'react'
import { Spinner } from 'react-bootstrap'
import { Card } from 'antd'

import TicketDrawer from './partials/TicketDrawer'
import TicketTable from './partials/TicketsTable'
import TicketsListTopBar from './partials/TicketsListTopBar'

import {getTickets} from './services'
import { handleError, parsePagination } from '../../helpers'

export default function TicketsList() {
   const [drawerId, setDrawerId] = useState(null)
   const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
   const [sort, setSort] = useState("-created_at")
   const [tickets, setTickets] = useState(null)
   const [filterType, setFilterType] = useState('user.document')
	const [filterValue, setFilterValue] = useState('')
   const [onlyPendding, setOnlyPendding] = useState(false)

   useEffect(()=>{
      !tickets && getTickets({
         ...pagination,
         [`filter[${filterType}]`]: filterValue,
         'filter[state]': onlyPendding ? 'pendding' : '',
         include: 'user,ticketType',
         sort,
      })
         .then(res => {
            setTickets(res.data.data)
            setPagination(parsePagination(res))
         })
         .catch(error => handleError(error))
   }, [tickets, pagination, filterType, filterValue, sort, onlyPendding])

   return (
      <React.Fragment>
         <TicketsListTopBar 
            reload={()=>setTickets(null)}
            filterType={filterType}
            setFilterType={setFilterType}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            onlyPendding={onlyPendding}
            setOnlyPendding={setOnlyPendding}
         />
         <Card title="Listado de Tickets">
            { tickets ? (
               <TicketTable 
                  setDrawerId={setDrawerId}
                  tickets={tickets}
                  pagination={pagination}
                  setPagination={setPagination}
                  reload={()=>setTickets(null)}
                  sort={sort}
                  setSort={setSort}
               />
            ) : (
               <Spinner animation="grow" />
            )}
         </Card>
         { drawerId && (
            <TicketDrawer 
               drawerId={drawerId} 
               closeDrawer={()=>setDrawerId(null)}
            /> 
         )}
      </React.Fragment>
   )
}
import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { Modal, DatePicker, message } from 'antd'
import { Form, Button } from 'react-bootstrap'
import moment from 'moment'

import { getUri } from '../../../services/asisfarmaApi'
import { storeExportToken } from '../../ExportTokens/services'
import { handleError } from '../../../helpers'

const { RangePicker } = DatePicker;

export default function TicketExport() {
	const { user } = useSelector(state => state.auth)
	const [openModal, setOpenModal] = useState(false)
	const [from, setFrom] = useState(moment().subtract(30,'days').format('YYYY-MM-DD'))
	const [to, setTo] = useState(moment().format('YYYY-MM-DD'))

	const handleExport = () => {
		const token = Date.now() + Math.floor(1000 + Math.random() * 9000);
		storeExportToken({
			user_id: user.id,
			type: `Exportar Tickets de Soporte`,
			token,
		})
			.then(() => {
				const url = getUri()
				window.open(`${url}/exports/tickets/${from}/${to}/open,solved,pendding/${token}`)
				message.success('Tickets exportados', 10);
				setOpenModal(false)
			})
			.catch(handleError)
	}

	const handleDatesChange = dates => {
		setFrom(dates[0].format('YYYY-MM-DD'))
		setTo(dates[1].format('YYYY-MM-DD'))
	}

	return (
		<React.Fragment>
			<Button variant="outline-primary" onClick={() => setOpenModal(true)}>Exportar Tickets</Button>
			{ openModal && (
				<Modal
					open
					onOk={handleExport}
					okText="Exportar a Excel"
					onCancel={() => setOpenModal(false)}
					cancelText="Cancelar"
					title="Exporta Tickets de Soporte"
				>
					<Form.Group className="mt-10">
						<Form.Label className="block">Rango de Fechas</Form.Label>
						<RangePicker
							defaultValue={[moment(from, 'YYYY-MM-DD'), moment(to, 'YYYY-MM-DD')]}
							onChange={dates => handleDatesChange(dates)}
						/>
					</Form.Group>
				</Modal>
			)}
		</React.Fragment>
	)
}
import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'

import DeliveriesExport from './DeliveriesExport'

export default function DeliveriesListTopBar({ 
    filterType, setFilterType, filterValue, setFilterValue, reload 
}) {
    return (
        <React.Fragment>
            <div className="mb-20">
                <DeliveriesExport scope="deliveries" />
            </div>
            <div className="text-right filters">
                <InputGroup>
                    <InputGroup.Text>
                        <Form.Control 
                            as="select" 
                            className="bg-light"
                            value={filterType}
                            onChange={e => setFilterType(e.target.value)}
                        >
                            <option value="authorizations..authorization_key">Buscar por número de autorización</option>
                            <option value="schedule_date">Buscar por fecha de agendamiento (YYYY-MM-DD)</option>
                            <option value="user.document">Buscar por cedula de paciente</option>
                        </Form.Control>
                    </InputGroup.Text>
                    <Form.Control 
                        placeholder="Escriba aquí" 
                        value={filterValue}
                        onChange={e => setFilterValue(e.target.value)}
                    />
                    <InputGroup.Text>
                        <Button color="primary" onClick={reload}>Buscar</Button>
                    </InputGroup.Text>
                </InputGroup>
            </div>
        </React.Fragment>
    )
}
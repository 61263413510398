import React from 'react'
import { Menu } from 'antd'
import { faMotorcycle, faLifeRing, faHospital, faCogs, faUserLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'

export default function SideMenu() {   

   const items = [
      {
         key: 'deliveries',
         label: (
            <Link to="/deliveries">
               <span>Domicilios</span>
            </Link>
         ),
         icon: <FontAwesomeIcon icon={faMotorcycle} />
      },
      {
         key: 'pickups',
         label: (
            <Link to="/pickups">
               <span>Entregas en Farmacia</span>
            </Link>
         ),
         icon: <FontAwesomeIcon icon={faHospital} />
      },
      {
         key: 'tickets',
         label: (
            <Link to="/tickets">
               <span>Tickets de Soporte</span>
            </Link>
         ),
         icon: <FontAwesomeIcon icon={faLifeRing} />
      },
      {
         key: 'users',
         label: (
            <Link to="/users">
               <span>Usuarios Administrador</span>
            </Link>
         ),
         icon: <FontAwesomeIcon icon={faUserLock} />
      },
      // {
      //    key: 'reports',
      //    label: (
      //       <Link to="/reports">
      //          <span>Reporte General</span>
      //       </Link>
      //    ),
      //    icon: <FontAwesomeIcon icon={faChartPie} />
      // },
      {
         key: 'settings',
         label: (
            <Link to="/settings">
               <span>Ajustes del Sistema</span>
            </Link>
         ),
         icon: <FontAwesomeIcon icon={faCogs} />
      }
   ]

   return (
      <Menu 
         theme="dark" 
         defaultSelectedKeys={[window.location.href.split('/')[4]]} 
         mode="inline" 
         items={items}
      />
   )
}
import asisfarmaApi from '../../services/asisfarmaApi'

export const storeAuthorization = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/authorizations', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const deleteAuthorization = id => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .delete(`/authorizations/${id}`)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)

export const syncData = data => (
   new Promise((resolve, reject) => {
      asisfarmaApi()
         .post('/sync_dynamics/data', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
   })
)
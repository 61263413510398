import React, {useState, useEffect} from 'react'
import { Spinner } from 'react-bootstrap'
import { Card } from 'antd'

import UserTable from './partials/UsersTable'
import UsersListTopBar from './partials/UsersListTopBar'

import { getUsers } from './services'
import { handleError, parsePagination } from '../../helpers'

export default function UsersList() {
    const [users, setUsers] = useState(null)
   const [pagination, setPagination] = useState({page: 1, per_page: 15, total: 0})
   const [sort, setSort] = useState("-created_at")
   const [filterType, setFilterType] = useState('document')
	const [filterValue, setFilterValue] = useState('')

   useEffect(()=>{
      !users && getUsers({
         ...pagination,
         [`filter[${filterType}]`]: filterValue,
         'filter[role]': 'admin',
         include: '',
         sort,
      })
         .then(res => {
            setUsers(res.data.data)
            setPagination(parsePagination(res))
         })
         .catch(error => handleError(error))
   }, [users, pagination, filterType, filterValue, sort])

   return (
      <React.Fragment>
         <UsersListTopBar 
            filterType={filterType}
            setFilterType={setFilterType}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            reload={()=>setUsers(null)}
         />
         <Card title="Listado de Usuarios Administradores">
            { users ? (
               <UserTable 
                  users={users}
                  pagination={pagination}
                  setPagination={setPagination}
                  reload={()=>setUsers(null)}
                  sort={sort}
                  setSort={setSort}
               />
            ) : (
               <Spinner animation="grow" />
            )}
         </Card>
      </React.Fragment>
   )
}
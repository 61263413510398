import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import AppLayout from '../../layouts/AppLayout'
import UsersList from './UsersList'

export default function Users() {
    return (
        <AppLayout title="Tickets de Soporte">
            <Switch>
                <Route exact path="/users" component={UsersList} />

                <Redirect to="/404" />
            </Switch>
        </AppLayout>
    )
}
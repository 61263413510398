import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { Table, Tooltip } from 'antd'
import moment from 'moment'

import Pagination from '../../../components/Pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import UpdateUserModal from './UpdateUserModal'

export default function UserTable({ 
   users, 
   reload,
   pagination,
   setPagination, 
   sort,
   setSort,
}) {   
    const [editModal, setEditModal] = useState(null)

   const columns = [
        {
            title: 'Nombre Completo',
            dataIndex: 'name',
            sorter: true,
            defaultSortOrder: sort === 'name' ? 'ascend' : sort === '-name' ? 'descend' : '',
        },
        {
            title: 'Correo Electrónico',
            dataIndex: 'email',
            sorter: true,
            defaultSortOrder: sort === 'email' ? 'ascend' : sort === '-email' ? 'descend' : '',
        },
        {
            title: 'Documento',
            dataIndex: 'document',
            sorter: true,
            defaultSortOrder: sort === 'document' ? 'ascend' : sort === '-document' ? 'descend' : '',
        },
        { 
            title: 'Fecha de Creación',
            dataIndex: 'created_at',
            render: text => moment(text).format('D MMM YYYY h:mma'),
            sorter: true,
            defaultSortOrder: sort === 'created_at' ? 'ascend' : sort === '-created_at' ? 'descend' : '',
        },
        { 
            title: 'Acciones',
            dataIndex: 'actions',
            render: (text, record) => (
                <React.Fragment>
                    <Tooltip title="Actualizar Usuario">
                        <Button 
                            variant="outline-primary" 
                            size="sm"
                            onClick={() => setEditModal(record)}
                        >
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Tooltip>
                </React.Fragment>
            )
        }
   ]

   const handleTableChange = (pagination, filters, sorter) => {
      if(sorter.column) setSort(`${sorter.order === 'ascend' ? '' : '-'}${sorter.column.dataIndex}`)
      else setSort('')
      reload()
   } 
   
   return (
        <React.Fragment>
            <Table
                dataSource={users}
                columns={columns}
                rowKey={r => r.id}
                onChange={handleTableChange}
                pagination={false}
                size="small"
            />
            <Pagination
                reload={reload}
                pagination={pagination}
                setPagination={setPagination}
            />
            { editModal && (
                <UpdateUserModal
                    user={editModal}
                    onClose={() => setEditModal(null)}
                    reload={reload}
                    updatePasswords
                />
            )}
        </React.Fragment>
   )
}
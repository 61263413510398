import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Button, Form, Spinner } from 'react-bootstrap'
import { Card, Empty, message } from 'antd'

import { deleteSetting, getSettings, storeSetting } from '../services'
import { handleError } from '../../../helpers'

export default function DeliveryTypesTab() {
	const [options, setOptions] = useState(null)
	const { handleSubmit, register, formState: { errors }, reset } = useForm()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		!options && getSettings({
			'filter[key]': 'delivery_types'
		})
			.then(res => setOptions(res.data.data))
			.catch(handleError)
	}, [ options ])

	const onSubmit = values => {
		setLoading(true)
		storeSetting({ key: 'delivery_types', ...values })
			.then(res => {
				message.success(res.data.message)
				setOptions(null)
				reset()
			})
			.catch(handleError)
			.finally(() => setLoading(false))
	}

	const handleDelete = id => {
		setLoading(true)
		deleteSetting(id)
			.then(res => {
				message.success(res.data.message)
				setOptions(null)
			})
			.catch(handleError)
			.finally(() => setLoading(false))
	}
	
	return (
		<Card>
			{ options ? (
				<div style={{ maxWidth: '450px' }}>
					{ options.length > 0 ? (
						<React.Fragment>
							{ options.map(opt => (
								<Card key={opt.id}>
									<h6>										
										<div className='float-right'>
											<Button 
												variant="outline-danger" 
												size="sm" 
												onClick={() => handleDelete(opt.id)}
												disabled={loading}
											>
												Eliminar
											</Button>
										</div>
										{opt.value}
									</h6>
								</Card>
							)) }
						</React.Fragment>
					) : (
						<Empty description="No tiene opciones creadas" />
					)}
					<hr />
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Form.Group className="form-group">
							<Form.Label>Nuevo Tipo de Entregas <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register("value", {required:true})}
								placeholder="Escriba aquí..."
							/>
							{ errors.value && <Form.Text className='text-warning'>Este campo es requerido</Form.Text> }
						</Form.Group>
						<Button color="primary" type="submit" disabled={loading}>
							Agregar Nuevo Tipo
						</Button>
					</Form>
				</div>
			) : <Spinner animation="border" />}
		</Card>
	)
}
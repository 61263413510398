import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default function MedicineForm({ register, errors }) {
    return (
        <React.Fragment>
            <Form.Group className="form-group">
                <Form.Label>Nombre del Medicamento <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    {...register("name", { required:true })}
                />
                { errors.name && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
            <Row>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>Cantidad Solicitada <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register("quantity_requested", { required:true })}
                    />
                    { errors.quantity_requested && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
                <Form.Group as={Col} className='form-group'>
                    <Form.Label>Cantidad Entregada <span className='text-danger'>*</span></Form.Label>
                    <Form.Control 
                        {...register("quantity_delivered", { required:true })}
                    />
                    { errors.quantity_delivered && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
                </Form.Group>
            </Row>
            <Form.Group className="form-group">
                <Form.Label>PLU <span className='text-danger'>*</span></Form.Label>
                <Form.Control 
                    {...register("plu", { required:true })}
                />
                { errors.plu && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
            </Form.Group>
        </React.Fragment>
    )
}
import React from 'react'
import { Form, InputGroup, Button } from 'react-bootstrap'

export default function UsersListTopBar({
    reload,
    filterType,
    setFilterType,
    filterValue,
    setFilterValue
}) {
    return (
        <React.Fragment>
            <div className="text-right filters">
                <InputGroup>
                    <InputGroup.Text>
                        <Form.Control 
                            as="select" 
                            className="bg-light"
                            value={filterType}
                            onChange={e => setFilterType(e.target.value)}
                        >
                            <option value="document">Buscar por cédula de usuario</option>
                            <option value="name">Buscar por nombre del usuario</option>
                        </Form.Control>
                    </InputGroup.Text>
                    <Form.Control 
                        placeholder="Escriba aquí" 
                        value={filterValue}
                        onChange={e => setFilterValue(e.target.value)}
                    />
                    <InputGroup.Text>
                        <Button color="primary" onClick={reload}>Buscar</Button>
                    </InputGroup.Text>
                </InputGroup>
            </div>
        </React.Fragment>
    )
}
import React, { useState, useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Divider, Empty, message, Modal, Popconfirm, Switch } from 'antd'
import moment from 'moment'
import styled from 'styled-components'

import NewAuthorizationNoSyncModal from '../../Authorizations/partials/NewAuthorizationNoSyncModal'
import AuthorizationMedicinesListModal from '../../Medicines/partials/AuthorizationMedicinesListModal'

import { getDelivery, notifyDelivery, updateDelivery } from '../services'
import { deleteAuthorization } from '../../Authorizations/services'
import { getSettings } from '../../Settings/services'
import { handleError } from '../../../helpers'

export default function VerifyDeliveryModal({ visible, onClose, deliveryId, reload }) {
	const [options, setOptions] = useState(null)
	const [shouldNotify, setShouldNotify] = useState(true)
	const [showNewAuthorizationModal, setShowNewAuthorizationModal] = useState(false)
	const [medicinesModal, setMedicinesModal] = useState(null)
	const [delivery, setDelivery] = useState(null)

	useEffect(() => {
		!delivery && getDelivery(deliveryId, {
			include: 'authorizations,user'
		})
			.then(res => setDelivery(res.data.data))
			.catch(handleError)
	}, [ delivery, deliveryId ])

	useEffect(() => {
		!options && getSettings({
			'filter[key]': 'delivery_types'
		})
			.then(res => setOptions(res.data.data))
			.catch(handleError)
	}, [ options ])
	
	const onSubmit = () => {
		updateDelivery(delivery.id, { verified_at: moment().format('YYYY-MM-DD HH:mm:ss') })
			.then(() => shouldNotify && notifyDelivery(delivery.id, { type: 'verified' }))
			.then(() => {
				message.success('Entrega verificada exitosamente.')
				shouldNotify && message.success('Notificación enviada exitosamente.')
				reload()
				onClose()
			})
			.catch(handleError)
	}

	const onDelete = id => {
		deleteAuthorization(id)
			.then(res => {
				message.info(res.data.message)
				setDelivery(null)
				reload()
			})
			.catch(handleError)
	}

	if(!delivery) return null

	const distinctAutorizations = [...new Map(delivery.authorizations.map(item =>
		[item['authorization_key'], item])).values()]
	
	return (
		<React.Fragment>
			<Modal open={visible} onCancel={onClose} footer={false}>
				<ModalBody>
					{ delivery.authorizations_keys ? (
						<React.Fragment>
							{ distinctAutorizations.map(authorization => (
								<Card key={authorization.id}>
									<div className='float-right'>
										<Popconfirm
											okText="Eliminar"
											cancelText="Cancelar"
											title="¿Seguro desea eliminar? Esta acción no puede revertirse"
											onConfirm={() => onDelete(authorization.id)}
											okButtonProps={{ danger:true }}
										>
											<Button variant="outline-danger" size="sm">
												Eliminar
											</Button>
										</Popconfirm>
										<Divider type="vertical" />
										<Button variant="outline-primary" size="sm" onClick={
											() => setMedicinesModal(authorization)
										}>
											Medicamentos
										</Button>
									</div>
									<h5>{authorization.authorization_key}</h5>
									<p>Sincronizada el {authorization.synced_at}</p>
								</Card>
							)) }
						</React.Fragment>
					) : (
						<Empty description="No tiene autorizaciones asociadas aún" className='mb-10' />
					)}
					<Button variant='outline-primary' onClick={() => setShowNewAuthorizationModal(true)}>
						Agregar Autorización
					</Button>
					<hr />
					<div className='mb-10'>
						<Switch checked={shouldNotify} onChange={()=>setShouldNotify(!shouldNotify)} />{" "}
						Notificar al cliente vía correo
					</div>
					<Button onClick={onSubmit} disabled={delivery.authorizations.length === 0}>
						Verificar Entrega
					</Button>
				</ModalBody>
			</Modal>
			{ showNewAuthorizationModal && (
				<NewAuthorizationNoSyncModal 
					visible
					onClose={() => setShowNewAuthorizationModal(false)}
					delivery={delivery}
					reload={() => {
						setDelivery(null)
						reload()
					}}
				/>
			)}
			{ medicinesModal && (
				<AuthorizationMedicinesListModal
					visible
					authorization={medicinesModal}
					onClose={() => setMedicinesModal(null)}
					reload={() => {}}
				/>
			)}
		</React.Fragment>
	)
}

const Card = styled.div`
	margin-bottom: 10px;
	padding: 7px 15px;
	background-color: #f8f8f8;
	h5 {
		margin-bottom: 0px;
	}
	p {
		margin-bottom: 0px;
	}
`

const ModalBody = styled.div`
	margin-top: 20px;
`
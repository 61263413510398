import React from 'react'
import DeliveriesExport from '../../Deliveries/partials/DeliveriesExport'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { Select } from 'antd'

export default function PickupsListTopBar(
    filterType,
    setFilterType,
    filterValue,
    setFilterValue,
    setPickups,
    selectedStores,
    setSelectedStores,
    stores,
) {
    return (
        <React.Fragment>
            <div className="mb-20">
                <DeliveriesExport scope="pickups" />
            </div>
            <div className="text-right filters">
                <InputGroup>
                    <InputGroup.Text>
                        <Form.Control 
                            as="select" 
                            className="bg-light"
                            value={filterType}
                            onChange={e => setFilterType(e.target.value)}
                        >
                            <option value="authorizations.authorization_key">Buscar por número de autorización</option>
                            <option value="schedule_date">Buscar por fecha de agendamiento (YYYY-MM-DD)</option>
                        </Form.Control>
                    </InputGroup.Text>
                    <Form.Control 
                        placeholder="Escriba aquí" 
                        value={filterValue}
                        onChange={e => setFilterValue(e.target.value)}
                    />
                    <InputGroup.Text>
                        <Button color="primary" onClick={()=>setPickups(null)}>Buscar</Button>
                    </InputGroup.Text>
                </InputGroup>
            </div>
            <div className="mb-20">
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select"
                    value={selectedStores}
                    onChange={values => {
                        setSelectedStores(values)
                        setPickups(null)
                    }}
                >
                { stores && stores.map(store => (
                    <Select.Option key={store.id} value={store.id}>{store.name}</Select.Option>
                )) }
                </Select>
            </div>
        </React.Fragment>
    )
}
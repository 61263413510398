import React, { useState } from 'react'
import { Alert, Modal, message } from 'antd'
import { Form } from 'react-bootstrap'

import { updateUser } from '../services'
import { handleError } from '../../../helpers'

export default function UpdateUserModal({ user, onClose, reload, updatePasswords = false }) {
	const [name, setName] = useState(user.name ? user.name : '')
	const [email, setEmail] = useState(user.email ? user.email : '')
	const [mobile, setMobile] = useState(user.mobile ? user.mobile : '')
	const [phone, setPhone] = useState(user.phone ? user.phone : '')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')

	const handleSubmit = () => {
		let values = { name, email, mobile, phone }
		
		if(values.email === user.email) 
			delete values.email

		if(password !== confirmPassword) 
			return message.warning('Las contraseñas no coinciden')

		if(password !== '') {
			values.password = password
			values.password_confirmation = confirmPassword
		}

		updateUser(user.id, values)
			.then(() => {
				message.success('Usuario actualizado exitosamente')
				reload()
				onClose()
			})
			.catch(error => handleError(error))
	}

	return (
		<Modal
			open={true}
			onCancel={onClose}
			onOk={handleSubmit}
			okText="Actualizar Usuario"
			cancelText="Cancelar"
			title="Datos de Contacto de Usuario"
		>
			<Form.Group className="form-group">
				<Form.Label>Nombre Completo</Form.Label>
				<Form.Control
					value={name}
					onChange={e => setName(e.target.value)}
				/>
			</Form.Group>
			<Form.Group className="form-group">
				<Form.Label>Correo Electrónico</Form.Label>
				<Form.Control
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
			</Form.Group>
			<Form.Group className="form-group">
				<Form.Label>Celular</Form.Label>
				<Form.Control
					value={mobile}
					onChange={e => setMobile(e.target.value)}
					type="number"
				/>
			</Form.Group>
			<Form.Group className="form-group">
				<Form.Label>Teléfono Secundario</Form.Label>
				<Form.Control
					value={phone}
					onChange={e => setPhone(e.target.value)}
					type="number"
				/>
			</Form.Group>
			{ updatePasswords && (
				<React.Fragment>
					<Alert type="warning" description="Deje en blanco sí no desea actualizar la contraseña" />
					<Form.Group className='mt-5'>
						<Form.Label>Nueva Contraseña</Form.Label>
						<Form.Control
							type="password"
							value={password}
							onChange={e => setPassword(e.target.value)}
						/>
					</Form.Group>
					<Form.Group className="form-group">
						<Form.Label>Confirmar Nueva Contraseña</Form.Label>
						<Form.Control
							type="password"
							value={confirmPassword}
							onChange={e => setConfirmPassword(e.target.value)}
						/>
					</Form.Group>
				</React.Fragment>
			)}
		</Modal>
	)
}
import React from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap'
import { Switch } from 'antd'

import TicketExport from './TicketExport'

export default function TicketsListTopBar({
    reload,
    filterType,
    setFilterType,
    filterValue,
    setFilterValue,
    onlyPendding,
    setOnlyPendding,
}) {
    return (
        <React.Fragment>
            <div className="mb-20">
                <TicketExport />
            </div>
            <div className="text-right filters">
                <InputGroup>
                    <InputGroup.Text>
                        <Form.Control 
                            as="select" 
                            className="bg-light"
                            value={filterType}
                            onChange={e => setFilterType(e.target.value)}
                        >
                            <option value="user.document">Buscar por cédula de cliente</option>
                            <option value="message">Buscar por texto en mensaje</option>
                        </Form.Control>
                    </InputGroup.Text>
                    <Form.Control 
                        placeholder="Escriba aquí" 
                        value={filterValue}
                        onChange={e => setFilterValue(e.target.value)}
                    />
                    <InputGroup.Text>
                        <Button color="primary" onClick={reload}>Buscar</Button>
                    </InputGroup.Text>
                </InputGroup>
            </div>
            <div className="mb-15">
                <Switch
                    checked={onlyPendding}
                    onChange={()=>{
                        setOnlyPendding(!onlyPendding)
                        reload()
                    }}
                    className="mr-5"
                />
                Ver solo tickets pendientes
            </div>
        </React.Fragment>
    )
}
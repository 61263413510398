import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Button, Form, Spinner, InputGroup, Row, Col } from 'react-bootstrap'
import { message, Modal } from 'antd'

import AuthorizationMedicinesListModal from '../../Medicines/partials/AuthorizationMedicinesListModal'

import { getSettings } from '../../Settings/services'
import { storeAuthorization } from '../services'
import { handleError } from '../../../helpers'

export default function NewAuthorizationNoSyncModal({ visible, onClose, delivery, reload }) {
	const { user } = useSelector(state => state.auth)
	const [options, setOptions] = useState(null)
	const [loading, setLoading] = useState(false)
	const [medicinesModal, setMedicinesModal] = useState(null)
	const { handleSubmit, register, formState: { errors } } = useForm({})

	useEffect(() => {
		!options && getSettings({ 'filter[key]': 'delivery_types' })
			.then(res => {
				setOptions(res.data.data.filter(setting => setting.key === 'delivery_types'))
			})
			.catch(handleError)
	}, [ options ])
	
	const onSubmit = async values => {
		setLoading(true)
		storeAuthorization({
			delivery_id: delivery.id,
			responsable_name: user.name,
			...values
		})
			.then(res => {
				message.success('Autorización vinculada exitosamente.')
				setMedicinesModal(res.data.data)
			})
			.catch(() => {
				message.error(`No se pudo almacenar autorización ${values.authorization_key}`)
				setLoading(false)
			})
	}
	
	return (
		<React.Fragment>
			<Modal open={visible} onCancel={onClose} footer={false}>
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Form.Group className="form-group">
						<Form.Label>Número de Autorización <span className='text-danger'>*</span></Form.Label>
						<InputGroup>
							<Form.Control 
								type="number"
								{...register("authorization_key", { required:true })}
								placeholder="Escriba aquí unicamente números"
							/>
						</InputGroup>
						{ errors.authorization_key && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
					</Form.Group>
					<Row>
						<Form.Group as={Col} className='form-group'>
							<Form.Label>Tiempo de Autorizacion (dias) <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								type="number"
								{...register("authorization_days", { required:true })}
							/>
							{ errors.authorization_days && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
						<Form.Group as={Col} className='form-group'>
							<Form.Label>Tipo de Entrega <span className='text-danger'>*</span></Form.Label>
							<Form.Control
								as="select"
								{...register("type", { required: true })}
							>
								<option value="">:: Seleccione una opción ::</option>
								{ options && options.map(opt => (
									<option key={opt.id} value={opt.value}>{opt.value}</option>
								)) }
							</Form.Control>
							{ errors.type && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
					</Row>
					<Row>
						<Form.Group as={Col} className='form-group'>
							<Form.Label>PV <span className='text-danger'>*</span></Form.Label>
							<Form.Control 
								{...register("pv", { required:true })}
							/>
							{ errors.pv && <Form.Text className='text-warning'>Este campo es requerido.</Form.Text> }
						</Form.Group>
					</Row>
					<hr />
					<Button type="submit" disabled={loading}>
						{ loading && <Spinner animation='border' size='sm' className='mr-5' /> }
						Agregar Autorización
					</Button>
				</Form>
			</Modal>
			{ medicinesModal && (
				<AuthorizationMedicinesListModal 
					visible
					authorization={medicinesModal}
					onClose={() => {
						reload()
						onClose()
					}}					
				/>
			)}
		</React.Fragment>
	)
}